var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { fetch: _vm.fetch, params: _vm.$route.params },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var info = ref.data
          return [
            _c("PageTitle", [_vm._v("Match")]),
            _c(
              "div",
              { staticClass: "match" },
              [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("MatchHead", {
                      attrs: {
                        bookmark: _vm.hasBookmark,
                        date: info.created,
                        event: info.event,
                        "map-id": info.map,
                        teams: info.teams,
                        "vod-status": info.vod_status
                      },
                      on: { "set:bookmark": _vm.setBookmark }
                    })
                  ],
                  1
                ),
                _c("MatchRoundSelector", {
                  staticClass: "justify-content-center mb-5",
                  attrs: {
                    rounds: info.roundStats,
                    teams: info.teams,
                    scores: _vm.scores
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "round",
                        fn: function(ref) {
                          var round = ref.round
                          return [
                            _c("RoundItem", {
                              key: round.round_number,
                              attrs: {
                                tag: "router-link",
                                active: round.round_number === _vm.roundNum,
                                to: { query: { round: round.round_number } },
                                round: round,
                                teams: info.teams
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.track_round(round.round_number + 1)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c(
                  "b-nav",
                  { attrs: { tabs: "" } },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: { path: "overview", query: _vm.$route.query },
                          "active-class": "active"
                        },
                        on: {
                          click: function($event) {
                            return _vm.track_tab("Overview")
                          }
                        }
                      },
                      [_vm._v(" Overview ")]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: { path: "economy", query: _vm.$route.query },
                          "active-class": "active"
                        },
                        on: {
                          click: function($event) {
                            return _vm.track_tab("Economy")
                          }
                        }
                      },
                      [_vm._v(" Economy ")]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: { path: "performance", query: _vm.$route.query },
                          "active-class": "active"
                        },
                        on: {
                          click: function($event) {
                            return _vm.track_tab("Performance")
                          }
                        }
                      },
                      [_vm._v(" K/D/A ")]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: { path: "notes", query: _vm.$route.query },
                          "active-class": "active"
                        },
                        on: {
                          click: function($event) {
                            return _vm.track_tab("Notes")
                          }
                        }
                      },
                      [_vm._v(" Notes ")]
                    ),
                    _c("li", { staticClass: "separator" }),
                    _c(
                      "b-nav-item",
                      {
                        attrs: { disabled: !_vm.mapPath, to: _vm.mapPath },
                        on: { click: _vm.trackMap }
                      },
                      [_vm._v(" 2D Map ")]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.exportMatch.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v(" Export ")]
                    )
                  ],
                  1
                ),
                _c("div", [_c("router-view", { attrs: { name: "match" } })], 1)
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }